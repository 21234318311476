/* ------------------------------------------------------------------ */
/* About Section
/* ------------------------------------------------------------------ */

#about {
  background: #2B2B2B;
  padding-top: 96px;
  padding-bottom: 66px;
  overflow: hidden;
}

#about h2 {
  font: 22px/30px 'opensans-bold', sans-serif;
  color: #fff;
  margin-bottom: 12px;
}
#about p {
  line-height: 30px;
  color: #7A7A7A;
}

#about .profile-pic {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 100%;
}
#about .details { width: 100%; }
#about .section-icon {
  width: 50px;
  height: 50px;
  color: #fff;
}


/* about social links */
#about .social {
  margin: 24px 0;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}
#about .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}
#about .social li a { color: #fff; }
#about .social li a:hover { color: #11ABB0; }


/* about article links */
#about .articles {
  margin: 24px 0;
  padding: 0;
  font-size: 18px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}
#about .articles li {
  display: block;
  left: 0;
  margin: 15px 15px;
  padding: 0;
}

#about .articles li a { color: #11ABB0; }
#about .articles li a:hover { color: #fff; }

#about .articles li span { font-size: 14px; }
