/* ------------------------------------------------------------------ */
/* Resume Section
/* ------------------------------------------------------------------ */

#resume {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 72px;
  overflow: hidden;
}

#resume h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#resume h1 span {
  border-bottom: 3px solid #11ABB0;
  padding-bottom: 6px;
}
#resume h3 {
  font: 25px/30px 'opensans-bold', sans-serif;
}

#resume .header-col { padding-top: 9px; }
#resume .main-col { text-align: left; padding-right: 10%; }

.education, .work, .certifications {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E8E8E8;
}

#resume .badge {
  vertical-align: middle;
  height: 60px;
  width: 60px;
}
#resume .info {
  font: 18px/24px 'librebaskerville-italic', serif;
  font-weight: bold;
  color: #335A9C;
  margin-bottom: 18px;
  margin-top: 9px;
}
#resume .info span {
  margin-right: 5px;
  margin-left: 5px;
}
#resume .date {
  font: 15px/24px 'opensans-regular', sans-serif;
  color: #6E7881;
  margin-top: 6px;
}

/* resume download link */
#resume .download {
  width: 100%;
  padding-top: 6px;
}
#resume .main-col { padding-right: 5%; }
#resume .download .button {
  margin-top: 6px;
  background: #444;
}
#resume .download .button:hover {
  background: #fff;
  color: #2B2B2B;
}
#resume .download .button i {
  margin-right: 15px;
  font-size: 20px;
}