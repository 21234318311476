/* ------------------------------------------------------------------ */
/* Header
/* ------------------------------------------------------------------ */

header {
  position: relative;
  width: 100%;
  height: 600px;
  background: #161415 url(/src/assets/images/headerImage2.png) no-repeat top center;
  background-size: cover;
	-webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
}

/* vertically center banner section */
header:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
header .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 10 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

header .banner-text { width: 100%; }
header .banner-text h1 {
  font: 80px/1.1em 'opensans-bold', sans-serif;
  color: #fff;
  letter-spacing: -2px;
  margin: 0 auto 18px auto;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, .8);
}
header .banner-text h3 {
  font: 20px/1.9em 'librebaskerville-regular', serif;
  color: #A8A8A8;
  margin: 0 auto;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
}
header .banner-text h3 span,
header .banner-text h3 a {
  color: #fff;
}
header .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2F2D2E;
  border-color: rgba(150, 150, 150, .1);
}

/* scrolldown link */
header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;
}

header .scrolldown a:hover { color: #11ABB0; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul, #nav-wrap li, #nav-wrap a {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* nav-wrap */
#nav-wrap {
  font: 12px 'opensans-bold', sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}

.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
  min-height: 48px;
  width: auto;
  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

/* Links */
ul#nav li a {
  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
	text-decoration: none;
  text-align: left;
  color: #fff;
}