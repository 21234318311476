/* ------------------------------------------------------------------ */
/* Portfolio Section
/* ------------------------------------------------------------------ */

#portfolio {
  background: #ebeeee;
  padding-top: 90px;
  padding-bottom: 60px;
}
#portfolio h1 {
  font: 15px/24px 'opensans-semibold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 48px;
  color: #95A3A3;
}

/* Portfolio Content */
#portfolio-wrapper .columns { margin-bottom: 36px; }
.portfolio-item .item-wrap {
  background: #fff;
  overflow: hidden;
  position: relative;
}
.portfolio-item .item-wrap a {
  display: block;
  cursor: pointer;
}

/* overlay */
.portfolio-item .item-wrap .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

	opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);
  background: url(/src/assets/images/overlay-bg.png) repeat;
}
.portfolio-item .item-wrap .link-icon {
  display: block;
  color: #fff;
  height: 30px;
  width: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;

  opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
}
.portfolio-item .item-wrap img {
  vertical-align: bottom;
}
.portfolio-item .portfolio-item-meta { padding: 18px }
.portfolio-item .portfolio-item-meta h5 {
  font: 14px/21px 'opensans-bold', sans-serif;
  color: #fff;
}
.portfolio-item .portfolio-item-meta p {
  font: 12px/18px 'opensans-light', sans-serif;
  color: #c6c7c7;
  margin-bottom: 0;
}

/* on hover */
.portfolio-item:hover .overlay {
	opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
  opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}